// src/PostList.js
import React, { useState, useEffect } from "react";
import { format } from "date-fns";

const host_api = process.env.REACT_APP_HOST_API
const PostList = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Fetch posts from the service
    const fetchPosts = async () => {
      try {
        const favourite_api = `${host_api}/api/v1/favourite`;
        const response = await fetch(favourite_api);
        const data = await response.json();
        console.log(data);
        setPosts(data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  const getDomain = (url) => {
    try {
      return new URL(url).hostname;
    } catch (e) {
      return "";
    }
  };

  return (
    <div>
      <h1>Posts</h1>
      <ul>
        {posts.map((post, index) => (
          <li key={index} style={{ marginBottom: "20px" }}>
            <a href={post.Url} target="_blank" rel="noopener noreferrer">
              {post.Title}
            </a>
            <div>
            <span style={{ fontSize: "0.9em", color: "#555", marginRight: '10px' }}>
              {getDomain(post.Url)}
            </span>
            <span style={{ fontSize: "0.9em", color: "#555" }}>
              {format(new Date(post.CreateAt), "yyyy-MM-dd HH:mm")}
            </span>
            
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PostList;
